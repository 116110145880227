.detail {
	width: 100%;
}

.smallDetail {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	align-items: flex-start;
	row-gap: 10px;
}

.smallDetailIconContainer {
	padding-left: 16px;
	padding-right: 16px;
}

.smallDetailIcon {
	width: 40px;
	height: 40px;
	flex: none;
	padding: 8px;
	border-radius: 8px;
	color: white;
	background-color: #2563eb;
}

.smallDetailTitle {
	font-size: var(--size-body-small);
	font-weight: 700;
	letter-spacing: var(--tracking-body-small);
	line-height: var(--leading-body-small);
	color: #0a2540;
	padding-left: 16px;
	padding-right: 16px;
}

.smallDetailBody {
	font-size: var(--size-body-small);
	font-weight: var(--weight-body-small);
	letter-spacing: var(--tracking-body-small);
	line-height: var(--leading-body-small);
	color: #425466;
	padding-left: 16px;
	padding-right: 16px;
}

.mediumDetail {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	align-items: flex-start;
	row-gap: 10px;
}

.mediumDetailIconContainer {
	padding-left: 16px;
	padding-right: 16px;
}

.mediumDetailIcon {
	width: 40px;
	height: 40px;
	flex: none;
	padding: 8px;
	border-radius: 8px;
	color: white;
	background-color: #2563eb;
}

.mediumDetailTitle {
	font-size: var(--size-body-small);
	font-weight: 700;
	letter-spacing: var(--tracking-body-small);
	line-height: var(--leading-body-small);
	color: #0a2540;
	padding-left: 16px;
	padding-right: 32px;
}

.mediumDetailBody {
	font-size: var(--size-body-small);
	font-weight: var(--weight-body-small);
	letter-spacing: var(--tracking-body-small);
	line-height: var(--leading-body-small);
	color: #425466;
	padding-left: 16px;
	padding-right: 32px;
}

.largeDetail {
	display: grid;
	grid-template-rows: auto;
	grid-template-columns: repeat(1, minmax(0, 1fr));
	align-items: flex-start;
	row-gap: 10px;
}

.largeDetailIconContainer {
	padding-left: 16px;
	padding-right: 16px;
	margin-bottom: 10px;
}

.largeDetailIcon {
	width: 40px;
	height: 40px;
	flex: none;
	padding: 8px;
	border-radius: 8px;
	color: white;
	background-color: #2563eb;
}

.largeDetailTitle {
	font-size: var(--size-body);
	font-weight: 700;
	letter-spacing: var(--tracking-body);
	line-height: var(--leading-body);
	color: #0a2540;
	padding-left: 16px;
	padding-right: 32px;
}

.largeDetailBody {
	font-size: var(--size-body);
	font-weight: var(--weight-body);
	letter-spacing: var(--tracking-body);
	line-height: var(--leading-body);
	color: #425466;
	padding-left: 16px;
	padding-right: 32px;
}

.largeDetailFooterContainer {
	padding-left: 16px;
	padding-right: 16px;
}
