.cardWrapper {
	position: relative;
	width: 100%;
	height: 74px;
	padding-left: 16px;
	padding-right: 16px;
}

.card {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 16px;
	overflow: hidden;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	border: 1px solid rgb(227, 232, 238);
	background-color: white;
	padding: 16px;
	box-shadow: 0 13.5px 27px -12.4px #32325d40, 0 8.1px 16.2px -8.1px #0000001a;
	cursor: pointer;
	grid-column: span 1 / span 1;
}

.text {
	font-size: var(--size-button);
	font-weight: var(--weight-button);
	letter-spacing: var(--tracking-button);
	line-height: var(--leading-button);
	color: #0a2540;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 14ch;
}

.imageWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 40px;
	height: 40px;
	flex: none;
	padding: 8px;
	border-radius: 8px;
	color: white;
}

.arrow {
	width: 10px;
	height: 10px;
	color: #0a2540;
	margin-top: 1px;
	margin-left: 8px;
	display: block;
	flex: none;
}

.textWrapper {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-left: 16px;
}
