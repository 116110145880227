.heroSection {
	position: relative;
	width: 100%;
}

.heroSection[data-theme="light"] {
	background-color: #f6fafe;
}

.heroSection[data-theme="dark"] {
	background-color: #0a2540;
}

.heroSection[data-theme="white"] {
	background-color: #ffffff;
}

.smallHeroSection[data-padding="small"] {
	padding-top: 80px;
	padding-bottom: 80px;
}

.smallHeroSection[data-padding="medium"] {
	padding-top: 120px;
	padding-bottom: 120px;
}

.smallHeroSection[data-padding="large"] {
	padding-top: 160px;
	padding-bottom: 160px;
}

.mediumHeroSection[data-padding="small"] {
	padding-top: 80px;
	padding-bottom: 80px;
}

.mediumHeroSection[data-padding="medium"] {
	padding-top: 120px;
	padding-bottom: 120px;
}

.mediumHeroSection[data-padding="large"] {
	padding-top: 160px;
	padding-bottom: 160px;
}

.largeHeroSection[data-padding="small"] {
	padding-top: 80px;
	padding-bottom: 80px;
}

.largeHeroSection[data-padding="medium"] {
	padding-top: 120px;
	padding-bottom: 120px;
}

.largeHeroSection[data-padding="large"] {
	padding-top: 160px;
	padding-bottom: 160px;
}

.smallHeroSection {
	position: relative;
	width: 100%;
	height: 600px;
	overflow: hidden;
}

.smallHeroSectionContent {
	padding-left: 16px;
	padding-right: 16px;
	position: relative;
	z-index: 60;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.mediumHeroSection {
	position: relative;
	width: 100%;
	height: 600px;
	overflow: hidden;
}

.mediumHeroSectionContent {
	padding-left: 16px;
	padding-right: 16px;
	position: relative;
	z-index: 60;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.largeHeroSection {
	position: relative;
	width: 100%;
	height: 800px;
	overflow: hidden;
}

.largeHeroSectionContent {
	max-width: 1080px;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	z-index: 60;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
