.title {
	width: 100%;
}

.smallTitle {
	font-size: var(--size-title-small);
	font-weight: var(--weight-title-small);
	letter-spacing: var(--tracking-title-small);
	line-height: var(--leading-title-small);
	color: #0a2540;
	padding-left: 16px;
	padding-right: 16px;
	font-family: var(--font-display);
	width: 100%;
}

.mediumTitle {
	font-size: var(--size-title-small);
	font-weight: var(--weight-title-small);
	letter-spacing: var(--tracking-title-small);
	line-height: var(--leading-title-small);
	color: #0a2540;
	padding-left: 16px;
	padding-right: 16px;
	font-family: var(--font-display);
	width: 100%;
}

.largeTitle {
	font-size: var(--size-title);
	font-weight: var(--weight-title);
	letter-spacing: var(--tracking-title);
	line-height: var(--leading-title);
	color: #0a2540;
	padding-left: 16px;
	padding-right: 16px;
	font-family: var(--font-display);
	width: 100%;
}
