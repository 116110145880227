.link {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.link:hover {
	cursor: pointer;
}

.linkIcon {
	width: 10px;
	height: 10px;
	margin: auto auto auto 8px;
	display: block;
}

.linkText {
	font-size: var(--size-button);
	letter-spacing: var(--tracking-button);
	line-height: var(--leading-button);
	font-weight: var(--weight-button);
}
