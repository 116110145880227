.caption {
	width: 100%;
}

.smallCaption {
	font-size: var(--size-caption);
	font-weight: var(--weight-caption);
	letter-spacing: var(--tracking-caption);
	line-height: var(--leading-caption);
	color: #2563eb;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
}

.mediumCaption {
	font-size: var(--size-caption);
	font-weight: var(--weight-caption);
	letter-spacing: var(--tracking-caption);
	line-height: var(--leading-caption);
	color: #2563eb;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
}

.largeCaption {
	font-size: var(--size-caption);
	font-weight: var(--weight-caption);
	letter-spacing: var(--tracking-caption);
	line-height: var(--leading-caption);
	color: #2563eb;
	padding-left: 16px;
	padding-right: 16px;
	width: 100%;
}
