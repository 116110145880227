.heroTitle {
	width: 100%;
}
.smallHeroTitle {
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.smallHeroTitleContainer {
	position: relative;
}

.smallHeroTitleText {
	font-size: var(--size-hero-small);
	letter-spacing: var(--tracking-hero-small);
	line-height: var(--leading-hero-small);
	font-weight: var(--weight-hero-small);
	color: #092540;
	font-family: var(--font-display);
}

.smallHeroTitleFirstOverlay {
	font-size: var(--size-hero-small);
	letter-spacing: var(--tracking-hero-small);
	line-height: var(--leading-hero-small);
	font-weight: var(--weight-hero-small);
	font-family: var(--font-display);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: linear-gradient(90deg, #2563eb, #2563eb);
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.smallHeroTitleSecondOverlay {
	font-size: var(--size-hero-small);
	letter-spacing: var(--tracking-hero-small);
	line-height: var(--leading-hero-small);
	font-weight: var(--weight-hero-small);
	font-family: var(--font-display);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: linear-gradient(90deg, #2563eb, #2563eb);
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.smallHeroTitleThirdOverlay {
	font-size: var(--size-hero-small);
	letter-spacing: var(--tracking-hero-small);
	line-height: var(--leading-hero-small);
	font-weight: var(--weight-hero-small);
	font-family: var(--font-display);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: linear-gradient(90deg, #2563eb, #2563eb);
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.mediumHeroTitle {
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.mediumHeroTitleContainer {
	position: relative;
}

.mediumHeroTitleText {
	font-size: var(--size-hero-small);
	letter-spacing: var(--tracking-hero-small);
	line-height: var(--leading-hero-small);
	font-weight: var(--weight-hero-small);
	color: #092540;
	font-family: var(--font-display);
}

.mediumHeroTitleFirstOverlay {
	font-size: var(--size-hero-small);
	letter-spacing: var(--tracking-hero-small);
	line-height: var(--leading-hero-small);
	font-weight: var(--weight-hero-small);
	font-family: var(--font-display);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: linear-gradient(90deg, #2563eb, #2563eb);
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.mediumHeroTitleSecondOverlay {
	font-size: var(--size-hero-small);
	letter-spacing: var(--tracking-hero-small);
	line-height: var(--leading-hero-small);
	font-weight: var(--weight-hero-small);
	font-family: var(--font-display);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: linear-gradient(90deg, #2563eb, #2563eb);
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.mediumHeroTitleThirdOverlay {
	font-size: var(--size-hero-small);
	letter-spacing: var(--tracking-hero-small);
	line-height: var(--leading-hero-small);
	font-weight: var(--weight-hero-small);
	font-family: var(--font-display);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: linear-gradient(90deg, #2563eb, #2563eb);
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.largeHeroTitle {
	width: 100%;
	padding-left: 16px;
	padding-right: 16px;
}

.largeHeroTitleContainer {
	position: relative;
}

.largeHeroTitleText {
	font-size: var(--size-hero);
	letter-spacing: var(--tracking-hero);
	line-height: var(--leading-hero);
	font-weight: var(--weight-hero);
	color: #092540;
	font-family: var(--font-display);
}

.largeHeroTitleFirstOverlay {
	font-size: var(--size-hero);
	letter-spacing: var(--tracking-hero);
	line-height: var(--leading-hero);
	font-weight: var(--weight-hero);
	font-family: var(--font-display);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: linear-gradient(90deg, #2563eb, #2563eb);
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.largeHeroTitleSecondOverlay {
	font-size: var(--size-hero);
	letter-spacing: var(--tracking-hero);
	line-height: var(--leading-hero);
	font-weight: var(--weight-hero);
	font-family: var(--font-display);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: linear-gradient(90deg, #2563eb, #2563eb);
	-webkit-text-fill-color: transparent;
	background-clip: text;
}

.largeHeroTitleThirdOverlay {
	font-size: var(--size-hero);
	letter-spacing: var(--tracking-hero);
	line-height: var(--leading-hero);
	font-weight: var(--weight-hero);
	font-family: var(--font-display);
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	opacity: 1;
	background-image: linear-gradient(90deg, #2563eb, #2563eb);
	-webkit-text-fill-color: transparent;
	background-clip: text;
}
